import api from "./index";

const prefix = "role";
const getRoleList = (query = {}) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );

export { getRoleList };
