import api from "./index";

const prefix = "payment-method";
const getPaymentMethodList = query =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map(key => key + "=" + query[key])
      .join("&")}`
  );

export {
  getPaymentMethodList,
};
