import {
  getTransactionList,
  getTransactionDetails,
  putTransaction,
  postTransaction,
  deleteTransaction,
  postTransactionReport,
  postTransactionBatch,
  postTransactionImport,
  postFinancialConsolidatedReport,
  getTransactionPersonList,
} from "api";

const list = (params) => getTransactionList(params);
const show = (id) => getTransactionDetails(id);
const update = (id, data) => putTransaction(id, data);
const store = (data) => postTransaction(data);
const destroy = (id) => deleteTransaction(id);
const report = (data) => postTransactionReport(data);
const storeBatch = (data) => postTransactionBatch(data);
const storeImport = (data) => postTransactionImport(data);
const consolidatedReport = (data) => postFinancialConsolidatedReport(data);
const personList = (search) => getTransactionPersonList(search);

export const transactionService = {
  list,
  show,
  update,
  store,
  destroy,
  report,
  storeBatch,
  storeImport,
  consolidatedReport,
  personList,
};
