import { NotificationManager } from "../components/common/react-notifications";
import { defaultDirection } from "../constants/defaultValues";

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "." + mm + "." + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes();
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};

export const setDirection = (localValue) => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

export const formatPhone = (valor) => {
  if (valor === null) return;

  if (valor.length === 10)
    return valor.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3");

  if (valor.length === 11)
    return valor.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");

  if (valor.length === 13)
    return valor.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/g, "+$1 ($2) $3-$4");
  return valor;
};

export const formatZipcode = (valor) => {
  if (valor.length === 8) return valor.replace(/(\d{5})(\d{3})/g, "$1-$2");
  return valor;
};

export const formatTaxId = (valor) => {
  if (valor.length === 11)
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  return valor.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
};

export const validateCPF = (cpf) => {
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;
  var soma = 0;
  var resto;
  for (var i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (var x = 1; i <= 10; x++)
    soma = soma + parseInt(cpf.substring(x - 1, x)) * (12 - x);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const processErrorApi = (err, setFieldError, callback) => {
  var msg = err.message;

  var params = null;

  if (err.response && err.response.data) {
    if (
      err.response.status === 422 &&
      err.response.data.hasOwnProperty("errors")
    ) {
      msg = "";
      params = err.response.data.errors;

      if (setFieldError) {
        Object.keys(err.response.data.errors).forEach((key) => {
          var val = err.response.data.errors[key];
          var fieldError = "";
          if (Array.isArray(val)) {
            val.forEach((itemError) => {
              if (typeof itemError === "string") {
                fieldError += itemError + "\r\n";
              }
            });
          }
          setFieldError(key, fieldError);
        });
      }

      Object.values(err.response.data.errors).forEach((val) => {
        if (Array.isArray(val)) {
          val.forEach((itemError) => {
            if (typeof itemError === "string") {
              msg += itemError + "\r\n";
            }
          });
        }
      });
    } else if (err.response.data.hasOwnProperty("message")) {
      msg = err.response.data.message;
    }
  }

  NotificationManager.error(
    msg,
    "Ocorreu um erro!",
    3000,
    null,
    null,
    "filled"
  );

  if (callback) {
    callback(params);
  }

  return msg;
};

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const textFromHexBG = (hex) => {
  var rgbCat = hexToRgb(hex);
  var L = 0.2126 * rgbCat.r + 0.7152 * rgbCat.g + 0.0722 * rgbCat.b;

  return L > 149 ? "#000000" : "#ffffff";
};

export const checkPermission = (needed, allowed) => {
  if (typeof allowed === "undefined") {
    console.error("allowed permissions must be passed to the checkPermission");
    return false;
  }
  return allowed.some((p) => p.slug === needed);
};

export const SHEET_SUPPORTED_FORMATS = [
  "text/csv",
  "text/plain",
  "application/excel",
  "application/vnd.ms-excel",
  "application/vnd.msexcel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const messageSuccess = (
  msg = "Operação realizada com sucesso",
  title = "Sucesso!"
) => {
  NotificationManager.success(msg, title, 3000, null, null, "");
};
export const messageError = (
  msg = "Ocorreu um problema na operação",
  title = "Atenção!"
) => {
  NotificationManager.error(msg, title, 3000, null, null, "");
};

export const formatCurrency = (value, style = "currency", currency = "BRL") => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style,
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
};

export const calculateGrowthPercentage = (currentAmount, lastAmount) => {
  if (lastAmount === 0) {
    return currentAmount > 0 ? 100 : 0; // Handle division by zero
  }
  // return (currentAmount / lastAmount) * 100;
  return ((lastAmount - currentAmount) / lastAmount) * 100;
};
