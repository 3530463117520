import api from "./index";

const prefix = "financial-account";
const getFinancialAccountList = query =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map(key => key + "=" + query[key])
      .join("&")}`
  );
const getFinancialAccountTypeList = query =>
  api.get(
    `${prefix}-type?${Object.keys(query)
      .map(key => key + "=" + query[key])
      .join("&")}`
  );
const getFinancialAccountDetails = id => api.get(`${prefix}/${id}`);
const postFinancialAccount = data => api.post(prefix, data);
const putFinancialAccount = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteFinancialAccount = id => api.delete(`${prefix}/${id}`);
const getFinancialAccountSuggestId = (name, type) => api.get(`${prefix}/id/suggest?name=${name}&type=${type}`);
const getFinancialAccountValidateId = id => api.get(`${prefix}/id/validate?id=${id}`);
const postFinancialAccountPhoto = (id, data) => api.post(`${prefix}/${id}/photo`, data, { headers: { 'Content-Type': 'multipart/form-data' } });

export {
  getFinancialAccountList,
  getFinancialAccountTypeList,
  getFinancialAccountDetails,
  postFinancialAccount,
  putFinancialAccount,
  deleteFinancialAccount,
  getFinancialAccountSuggestId,
  getFinancialAccountValidateId,
  postFinancialAccountPhoto
};
