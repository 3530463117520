import api from "./index";

const prefix = "midia";
const getMidiaList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getMidiaDetails = (id) => api.get(`${prefix}/${id}`);
const postMidia = (data) => api.post(prefix, data);
const putMidia = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteMidia = (id) => api.delete(`${prefix}/${id}`);
const postMidiaPhoto = (id, data) =>
  api.post(`${prefix}/${id}/photo`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export {
  getMidiaList,
  getMidiaDetails,
  postMidia,
  putMidia,
  deleteMidia,
  postMidiaPhoto,
};
