import {
  getPrintableList,
  postPrintablePrint,
  postPrintableCredential,
} from "api";

const list = (params) => getPrintableList(params);
const print = (id, data) => postPrintablePrint(id, data);
const credential = (data) => postPrintableCredential(data);

export const printableService = {
  list,
  print,
  credential,
};
