import api from "./index";

const prefix = "transaction/import-ofx";
const getTransactionImportOfxLast = () => api.get(`${prefix}/last`);
const postTransactionImportOfx = (data) =>
  api.post(`${prefix}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
const putTransactionImportOfxItem = (importId, itemId, data) =>
  api.put(`${prefix}/${importId}/item/${itemId}`, data);
const deleteTransactionImportOfxItem = (importId, itemId) =>
  api.delete(`${prefix}/${importId}/item/${itemId}`);

const putTransactionImportOfxConsolidate = (importId) =>
  api.put(`${prefix}/${importId}/consolidate`);
const putTransactionImportOfxCancel = (importId) =>
  api.put(`${prefix}/${importId}/cancel`);

export {
  getTransactionImportOfxLast,
  postTransactionImportOfx,
  putTransactionImportOfxItem,
  deleteTransactionImportOfxItem,
  putTransactionImportOfxConsolidate,
  putTransactionImportOfxCancel,
};
