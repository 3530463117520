import api from "./index";

const prefix = "printable";
const getPrintableList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const postPrintablePrint = (id, data) =>
  api.post(`${prefix}/${id}/print`, data, {
    headers: {
      Accept: "application/pdf",
    },
    responseType: "blob",
  });
const postPrintableCredential = (data) =>
  api.post(`${prefix}/credential`, data, {
    headers: {
      Accept: "application/pdf",
    },
    responseType: "blob",
  });

export { getPrintableList, postPrintablePrint, postPrintableCredential };
