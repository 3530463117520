import api from "./index";

const prefix = "survey";
const getSurveyList = query =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map(key => key + "=" + query[key])
      .join("&")}`
  );
const getSurveyDetails = id => api.get(`${prefix}/${id}`);
const postSurvey = data => api.post(prefix, data);
const putSurvey = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteSurvey = id => api.delete(`${prefix}/${id}`);

export {
  getSurveyList,
  getSurveyDetails,
  postSurvey,
  putSurvey,
  deleteSurvey,
};
