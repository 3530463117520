import api from "./index";

const prefix = "pdv/sell-intention";
const getPdvTransactionList = query =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map(key => key + "=" + query[key])
      .join("&")}`
  );
const getPdvTransactionDetails = id => api.get(`${prefix}/${id}`);

export {
  getPdvTransactionList,
  getPdvTransactionDetails,
};
