import api from "./index";

const getFinancialDashboard = (query) =>
  api.get(
    `financial/dashboard?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );

const getHomeDashboard = (query) =>
  api.get(
    `home/dashboard?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );

const getSecretariatDashboard = (query) =>
  api.get(
    `secretariat/dashboard?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );

export { getFinancialDashboard, getHomeDashboard, getSecretariatDashboard };
