import api from "./index";

const prefix = "ordination";
const getOrdinationList = query =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map(key => key + "=" + query[key])
      .join("&")}`
  );
const getOrdinationDetails = id => api.get(`${prefix}/${id}`);
const postOrdination = data => api.post(prefix, data);
const putOrdination = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteOrdination = id => api.delete(`${prefix}/${id}`);

export {
  getOrdinationList,
  getOrdinationDetails,
  postOrdination,
  putOrdination,
  deleteOrdination,
};
