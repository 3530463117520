import {
  getSurveyList,
  getSurveyDetails,
  putSurvey,
  postSurvey,
  deleteSurvey,
} from "api";

const list = (params) => getSurveyList(params);
const show = (id) => getSurveyDetails(id);
const update = (id, data) => putSurvey(id, data);
const store = (data) => postSurvey(data);
const destroy = (id) => deleteSurvey(id);

export const surveyService = {
  list,
  show,
  update,
  store,
  destroy,
};
