import api from "./index";

const prefix = "event";

// CRUD
const getEventList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getEventDetails = (id) => api.get(`${prefix}/${id}`);
const postEvent = (data) => api.post(prefix, data);
const putEvent = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteEvent = (id) => api.delete(`${prefix}/${id}`);
const postEventPhoto = (id, data) =>
  api.post(`${prefix}/${id}/photo`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
const postEventCheckInAll = (id) =>
  api.post(`${prefix}/${id}/check-in-all`, {});

const getEventBaptismReport = (id) => api.get(`${prefix}/${id}/report/baptism`);
const getEventSubscriptionExcelReport = (id) =>
  api.get(`${prefix}/${id}/report/subscription-excel`, {
    headers: { Accept: "*/*" },
    responseType: "blob",
  });

// SUBSCRIPTIONS
const getEventSubscriptionList = (eventId, query) =>
  api.get(
    `${prefix}/${eventId}/subscription?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getEventSubscriptionDetails = (eventId, id) =>
  api.get(`${prefix}/${eventId}/subscription/${id}`);
const postEventSubscription = (eventId, data) =>
  api.post(`${prefix}/${eventId}/subscription`, data);
const putEventSubscription = (eventId, id, data) =>
  api.put(`${prefix}/${eventId}/subscription/${id}`, data);
const deleteEventSubscription = (eventId, id) =>
  api.delete(`${prefix}/${eventId}/subscription/${id}`);
const postEventSubscriptionGreeting = (eventId, id) =>
  api.post(`${prefix}/${eventId}/subscription/${id}/greeting`, {});
const putEventSubscriptionCancel = (eventId, id, cancel, reason) =>
  api.put(`${prefix}/${eventId}/subscription/${id}/cancel`, {
    mode: cancel ? "CANCEL" : "UNCANCEL",
    reason,
  });
const putEventSubscriptionCheck = (eventId, id, mode) =>
  api.put(`${prefix}/${eventId}/subscription/${id}/check`, {
    mode,
  });

// LOT
const getEventLotList = (eventId, query) =>
  api.get(
    `${prefix}/${eventId}/lot?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getEventLotDetails = (eventId, id) =>
  api.get(`${prefix}/${eventId}/lot/${id}`);
const postEventLot = (eventId, data) =>
  api.post(`${prefix}/${eventId}/lot`, data);
const putEventLot = (eventId, id, data) =>
  api.put(`${prefix}/${eventId}/lot/${id}`, data);
const deleteEventLot = (eventId, id) =>
  api.delete(`${prefix}/${eventId}/lot/${id}`);

// TICKET TYPE
const getEventTicketTypeList = (eventId, query) =>
  api.get(
    `${prefix}/${eventId}/ticket-type?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getEventTicketTypeDetails = (eventId, id) =>
  api.get(`${prefix}/${eventId}/ticket-type/${id}`);
const postEventTicketType = (eventId, data) =>
  api.post(`${prefix}/${eventId}/ticket-type`, data);
const putEventTicketType = (eventId, id, data) =>
  api.put(`${prefix}/${eventId}/ticket-type/${id}`, data);
const deleteEventTicketType = (eventId, id) =>
  api.delete(`${prefix}/${eventId}/ticket-type/${id}`);

// DASH
const getEventDashInfo = (eventId) => api.get(`${prefix}/${eventId}/dash`);

// ORDER
const getEventOrderList = (eventId, query) =>
  api.get(
    `${prefix}/${eventId}/order?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getEventOrderDetails = (eventId, id) =>
  api.get(`${prefix}/${eventId}/order/${id}`);
const postEventOrder = (eventId, data) =>
  api.post(`${prefix}/${eventId}/order`, data);
const putEventOrder = (eventId, id, data) =>
  api.put(`${prefix}/${eventId}/order/${id}`, data);
const deleteEventOrder = (eventId, id) =>
  api.delete(`${prefix}/${eventId}/order/${id}`);
const postEventOrderNotifyWhatsApp = (eventId, id) =>
  api.post(`${prefix}/${eventId}/order/${id}/notify-whatsapp`, {});

// ORGANIZERS
const getAllOrganizersUsers = () => {
  return api.get(`${prefix}/organizer-users`);
};
const postEventOrganizer = (eventId, params) => {
  return api.post(`${prefix}/${eventId}/organizer`, params);
};
const deleteEventOrganizer = (eventId, userId) => {
  return api.delete(`${prefix}/${eventId}/organizer/${userId}`);
};

export {
  getEventList,
  getEventDetails,
  postEvent,
  putEvent,
  deleteEvent,
  postEventPhoto,
  getEventSubscriptionList,
  getEventSubscriptionDetails,
  postEventSubscription,
  putEventSubscription,
  deleteEventSubscription,
  getEventDashInfo,
  postEventSubscriptionGreeting,
  putEventSubscriptionCancel,
  putEventSubscriptionCheck,
  getEventLotList,
  getEventLotDetails,
  postEventLot,
  putEventLot,
  deleteEventLot,
  getEventTicketTypeList,
  getEventTicketTypeDetails,
  postEventTicketType,
  putEventTicketType,
  deleteEventTicketType,
  postEventCheckInAll,
  getEventBaptismReport,
  getEventOrderList,
  getEventOrderDetails,
  postEventOrder,
  putEventOrder,
  deleteEventOrder,
  postEventOrderNotifyWhatsApp,
  getAllOrganizersUsers,
  postEventOrganizer,
  deleteEventOrganizer,
  getEventSubscriptionExcelReport,
};
