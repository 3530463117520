import {
  getTransactionCategoryList,
  getTransactionCategoryDetails,
  putTransactionCategory,
  postTransactionCategory,
  deleteTransactionCategory,
} from "api";

const list = (params) => getTransactionCategoryList(params);
const show = (id) => getTransactionCategoryDetails(id);
const update = (id, data) => putTransactionCategory(id, data);
const store = (data) => postTransactionCategory(data);
const destroy = (id) => deleteTransactionCategory(id);

export const transactionCategoryService = {
  list,
  show,
  update,
  store,
  destroy,
};
