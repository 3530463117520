import api from "./index";

const prefix = "closure";
const getClosureList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getClosureDetails = (id) => api.get(`${prefix}/${id}`);
const postClosure = (data) => api.post(prefix, data);
const putClosure = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteClosure = (id) => api.delete(`${prefix}/${id}`);
const putClosureApprove = (id) => api.put(`${prefix}/${id}/approve`);
const postSubmitForApproval = (id) =>
  api.post(`${prefix}/${id}/submit-for-approval`);

export {
  getClosureList,
  getClosureDetails,
  postClosure,
  putClosure,
  deleteClosure,
  putClosureApprove,
  postSubmitForApproval,
};
