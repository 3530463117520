import {
  getFinancialAccountList,
  getFinancialAccountTypeList,
  getFinancialAccountDetails,
  putFinancialAccount,
  postFinancialAccount,
  deleteFinancialAccount,
  getFinancialAccountSuggestId,
  getFinancialAccountValidateId,
  postFinancialAccountPhoto,
} from "api";

const list = (params) => getFinancialAccountList(params);
const listTypes = (params) => getFinancialAccountTypeList(params);
const show = (id) => getFinancialAccountDetails(id);
const update = (id, data) => putFinancialAccount(id, data);
const store = (data) => postFinancialAccount(data);
const destroy = (id) => deleteFinancialAccount(id);
const suggestId = (name, type) => getFinancialAccountSuggestId(name, type);
const validateId = (id) => getFinancialAccountValidateId(id);
const updatePhoto = (id, data) => postFinancialAccountPhoto(id, data);

export const financialAccountService = {
  list,
  listTypes,
  show,
  update,
  store,
  destroy,
  suggestId,
  validateId,
  updatePhoto,
};
