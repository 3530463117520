import api from "./index";

const prefix = "person";
const getPersonList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getPersonDetails = (id) => api.get(`${prefix}/${id}`);
const postPerson = (data) => api.post(prefix, data);
const putPerson = (id, data) => api.put(`${prefix}/${id}`, data);
const deletePerson = (id) => api.delete(`${prefix}/${id}`);
const getPersonValidateTaxId = (taxId) =>
  api.get(`${prefix}/tax-id/validate?tax_id=${taxId}`);
const getPersonValidateId = (id) => api.get(`${prefix}/id/validate?id=${id}`);
const postPersonPhoto = (id, data) =>
  api.post(`${prefix}/${id}/photo`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
const getPersonHistory = (id) => api.get(`${prefix}/${id}/history`);
const putPersonApprove = (id) => api.put(`${prefix}/${id}/approve`, {});
const putPersonStatus = (id, data) => api.put(`${prefix}/${id}/status`, data);
const postPersonReportCustom = (data) =>
  api.post(`${prefix}/report/custom`, data);

// importações
const getPersonImportList = (query) =>
  api.get(
    `${prefix}/import?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const postPersonImport = (data) =>
  api.post(`${prefix}/import`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

const putPersonChurch = (id, newChurchId) =>
  api.put(`${prefix}/${id}/church`, { church_id: newChurchId });

export {
  getPersonList,
  getPersonDetails,
  postPerson,
  putPerson,
  deletePerson,
  getPersonValidateTaxId,
  getPersonValidateId,
  postPersonPhoto,
  getPersonHistory,
  putPersonApprove,
  putPersonStatus,
  postPersonReportCustom,
  getPersonImportList,
  postPersonImport,
  putPersonChurch,
};
