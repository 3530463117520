import api from "./index";

const prefix = "transaction-category";
const getTransactionCategoryList = query =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map(key => key + "=" + query[key])
      .join("&")}`
  );
const getTransactionCategoryDetails = id => api.get(`${prefix}/${id}`);
const postTransactionCategory = data => api.post(prefix, data);
const putTransactionCategory = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteTransactionCategory = id => api.delete(`${prefix}/${id}`);

export {
  getTransactionCategoryList,
  getTransactionCategoryDetails,
  postTransactionCategory,
  putTransactionCategory,
  deleteTransactionCategory,
};
