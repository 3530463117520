import {
  getOrdinationList,
  getOrdinationDetails,
  putOrdination,
  postOrdination,
  deleteOrdination,
} from "api";

const list = (params) => getOrdinationList(params);
const show = (id) => getOrdinationDetails(id);
const update = (id, data) => putOrdination(id, data);
const store = (data) => postOrdination(data);
const destroy = (id) => deleteOrdination(id);

export const ordinationService = {
  list,
  show,
  update,
  store,
  destroy,
};
