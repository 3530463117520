import api from "./index";

const prefix = "group";
const getGroupList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getGroupDetails = (id) => api.get(`${prefix}/${id}`);
const postGroup = (data) => api.post(prefix, data);
const putGroup = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteGroup = (id) => api.delete(`${prefix}/${id}`);
const postGroupPhoto = (id, data) =>
  api.post(`${prefix}/${id}/photo`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export {
  getGroupList,
  getGroupDetails,
  postGroup,
  putGroup,
  deleteGroup,
  postGroupPhoto,
};
