import api from "./index";

const prefix = "music";
const getMusicList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getMusicDetails = (id) => api.get(`${prefix}/${id}`);
const postMusic = (data) => api.post(prefix, data);
const putMusic = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteMusic = (id) => api.delete(`${prefix}/${id}`);
const postMusicPhoto = (id, data) =>
  api.post(`${prefix}/${id}/photo`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export {
  getMusicList,
  getMusicDetails,
  postMusic,
  putMusic,
  deleteMusic,
  postMusicPhoto,
};
