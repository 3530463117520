import {
  getChurchList,
  getChurchDetails,
  putChurch,
  postChurch,
  deleteChurch,
  getChurchSuggestId,
  getChurchValidateId,
  postChurchPhoto,
} from "api";
import { processErrorApi } from "helpers/Utils";

const list = (params) => getChurchList(params);
const show = (id) => getChurchDetails(id);
const update = (id, data) => putChurch(id, data);
const store = (data) => postChurch(data);
const destroy = (id) => deleteChurch(id);
const suggestId = (name) => getChurchSuggestId(name);
const validateId = (id) => getChurchValidateId(id);
const updatePhoto = (id, data) => postChurchPhoto(id, data);
const selectOptions = (params = {}) => {
  return new Promise((resolve, reject) => {
    getChurchList(params)
      .then(({ data }) => {
        resolve(
          data.map((item) => {
            return {
              value: item.id,
              label: `${item.custom_id} - ${item.name}`,
            };
          })
        );
      })
      .catch((err) => {
        processErrorApi(err);
        reject(err);
      });
  });
};

export const churchService = {
  list,
  show,
  update,
  store,
  destroy,
  suggestId,
  validateId,
  updatePhoto,
  selectOptions,
};
