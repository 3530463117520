import api from "./index";

const prefix = "workflow";
const getWorkflowList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getWorkflowDetails = (id) => api.get(`${prefix}/${id}`);

const getWorkflowItemList = (workflowId, query) =>
  api.get(
    `${prefix}/${workflowId}/item?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );

const getWorkflowItemDetails = (workflowId, itemId) =>
  api.get(`${prefix}/${workflowId}/item/${itemId}`);

const putWorkflowItem = (workflowId, itemId, data) =>
  api.put(`${prefix}/${workflowId}/item/${itemId}`, data);

const deleteWorkflowItem = (workflowId, itemId) =>
  api.delete(`${prefix}/${workflowId}/item/${itemId}`);

export {
  getWorkflowList,
  getWorkflowDetails,
  getWorkflowItemList,
  getWorkflowItemDetails,
  putWorkflowItem,
  deleteWorkflowItem,
};
