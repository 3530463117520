import api from "./index";

const prefix = "reception-type";
const getReceptionTypeList = (query) =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map((key) => key + "=" + query[key])
      .join("&")}`
  );
const getReceptionTypeDetails = (id) => api.get(`${prefix}/${id}`);

export { getReceptionTypeList, getReceptionTypeDetails };
