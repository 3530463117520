import api from "./index";

const prefix = "church";
const getChurchList = query =>
  api.get(
    `${prefix}?${Object.keys(query)
      .map(key => key + "=" + query[key])
      .join("&")}`
  );
const getChurchDetails = id => api.get(`${prefix}/${id}`);
const postChurch = data => api.post(prefix, data);
const putChurch = (id, data) => api.put(`${prefix}/${id}`, data);
const deleteChurch = id => api.delete(`${prefix}/${id}`);
const getChurchSuggestId = name => api.get(`${prefix}/id/suggest?name=${name}`);
const getChurchValidateId = id => api.get(`${prefix}/id/validate?id=${id}`);
const postChurchPhoto = (id, data) => api.post(`${prefix}/${id}/photo`, data, { headers: { 'Content-Type': 'multipart/form-data' } });

export {
  getChurchList,
  getChurchDetails,
  postChurch,
  putChurch,
  deleteChurch,
  getChurchSuggestId,
  getChurchValidateId,
  postChurchPhoto
};
